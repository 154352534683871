<template>
    <b-row> 
        <b-col md="6" sm="6" class="my-1">
            <b-form-group class="mb-0">
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>

        <b-col md="6" class="my-1">
            <b-form-group label=" " label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(avatar)="data">
                    <b-avatar :src="data.value" />
                </template>

                <template #cell(status)="data">
                    <b-row>
                        <b-col md="6">
                            <b-badge variant="primary" 
                            v-if="data.item.st =='1'"  @click="act_status(data.item)"
                            class="pr-1 pl-1">
                                <i class="fa fa-check "></i>  
                            </b-badge>
                            <b-badge variant="danger"   @click="act_status(data.item)"
                            v-if="data.item.st =='0'"  
                            class="pr-1 pl-1">
                                <i class="fa fa-warning  "></i>  
                            </b-badge>
                        </b-col>
                        <b-col md="6">
                            <b-badge variant="warning" @click="get_edit(data.item)"  >
                                <i class="fa fa-pencil mr-1"></i> Edit
                            </b-badge>  
                        </b-col>
                    </b-row>
                </template>

            </b-table>
        </b-col>
        <b-col cols="6"></b-col>
        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
    import axios from '@/config/Axios'
    import Base from '@/config/Mixins_base';
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'

    export default {
        mixins: [Base],
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        data() {
            return {
                perPage: 5,
                pageOptions: [5, 10, 100, 1000],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [{
                        key: 'nama_jabatan',
                        label: 'Nama Jabatan',
                        sortable: true
                    },
                    {
                        key: 'st_jab',
                        label: 'Status',
                        sortable: true
                    },
                    {
                        key: 'ket',
                        label: 'Keterangan',
                        sortable: true
                    },
                    {
                        key: 'status',
                        label: '',
                    },


                ],
                

            }
        },
        emits:["editsData"],
        props:{
            items : {}
        },
        computed: {
            sortOptions() {
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            this.totalRows = this.items.length;
            this.laod_data()
        },
        methods: {
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`
                this.infoModal.content = JSON.stringify(item, null, 2)
                this.$root.$emit('bv::show::modal', this.infoModal.id, button)
            },
            resetInfoModal() {
                this.infoModal.title = ''
                this.infoModal.content = ''
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },

            async act_status(data) {
                    const self = this;
                    await axios({
                        method: 'POST',
                        url: '/api/master/jabatan/status',
                        data: {
                            id_jabatan : data.id_jabatan
                        },
                        headers: {
                          'Authorization': self.isAuthenticated
                        }
                      })
                      .then(function (response) {
                        self.laod_data() 
                        self.notification('info', "", response.data.message);
            
                      }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                      });
                  },

            async laod_data() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/master/jabatan/load_data',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.items = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },

            get_edit(value){ 
               this.$emit("editsData", value);
            }
        },
    }
</script>